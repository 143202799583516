.super-state-label {
  font-size: 20px;
  display: block;
  text-align: left;
  margin: 10px;
}


.super-state.super-state-alt {
  min-height: 240px;
}

.super-state {
  border-radius: 8px;
  background-color: #f8f7fa;

  padding: 12px;
  width: 300px;
  text-align: center;
  font-weight: bold;
  color: black;
  cursor: default;
  line-height: 1.2;
  min-height: 150px;
  z-index: -1;
}

.super-state .pathway-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.super-state .pathway-header .p-button.add-state-btn {
  background: white;
  color: black;
  border: 0px;
  border-radius: 5px;
  width: 25px;
  height: 25px;
}

.super-state .pathway-header .p-button.add-state-btn .pi {
  font-size: 12px;
  font-weight: 900;
}

.super-state-alternatives {
  color: #778699;
  text-align: left;
  padding-left: 10px;
  margin-top: 75px;
}


hr {
  border: 1px solid #ebebeb;
}