.pathway-node-label {
  font-size: 20px;
  /* font-size: vmax; */
  /* font-weight: bold; */
  display: block;
  text-align: left;
  margin: 10px;
}

.pathway-node {
  /* border: 1px solid #lightgrey\ */
  /* border: 1px solid #0984e3; */
  border: 1px solid black;
  border-radius: 4px;
  background-color: #fafafa;
  /* background-color: #fff; */
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  padding: 12px;
  height: 700px;
  width: 300px;
  text-align: center;
  font-weight: bold;
  /* color: #0984e3; */
  /* color: #FF0072; */
  color: black;
  cursor: pointer;
  line-height: 1.2;
}

.pathway-node .pathway-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pathway-node .pathway-header .p-button.add-state-btn {
  background: black;
  border: 0px;
  border-radius: 5px;
  width: 25px;
  height: 25px;
}

.pathway-node .pathway-header .p-button.add-state-btn .pi {
  font-size: 12px;
  font-weight: 900;
}

hr {
  border: 1px solid #ebebeb;
}

/* .pathway-node .pathway-header .p-button {
    background: black;
    border: none
} */

.react-flow__node-pathway {
  z-index: -1 !important;
}

.pathway-node .react-flow__handle {
  opacity: 0;
  width: 20px;
  height: 40px;
  border-radius: 0px;
}
