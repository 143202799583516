.state-node-content {
  font-size: 15px;
  /* font-size: 12px; */
}

.state-node {
  /* border: 1px solid black; */
  border-radius: 4px;
  background-color: #fff;
  transition: background-color 0.2s linear;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  border: 1px solid black;
  padding: 12px;
  width: 250px;
  height: 100%;
  text-align: center;
  font-weight: bold;
  /* color: #0984e3; */
  color: black;
  cursor: pointer;
  line-height: 1.2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.state-node:hover {
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgb(252, 252, 252) 0%,
    rgb(239, 241, 243) 90%
  );
}

.state-node .react-flow__handle-right {
  right: -10px;
}

.state-node .react-flow__handle-left {
  left: -10px;
}

@keyframes progress {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.state-node.loading {
  animation: progress 1.2s ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #27c647, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
}

.state-modal {
  width: 50vw;
}

.state-modal-body {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.state-modal-btn-group {
  display: flex;
  gap: 1em;
}