@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

body {
  margin: 0;
  font-family: "poppins", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.editor {
  /* height: 700px; */
  /* width: 1400px; */
  height: 100vh;
  width: 100%;
  background-color: white;
}

.header {
  min-width: 100px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
}

.header .react-flow__controls-button {
  border: none;
  background: #fefefe;
  font-family: "Poppins";
  /* font-family: 'poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-weight: bold;
  border-bottom: 1px solid #eee;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding: 5px;
  z-index: 1000;
}

.p-component {
  -webkit-font-smoothing: antialiased;
}

code {
  -webkit-font-smoothing: antialiased;
}

.hidden {
  display: none !important;
}
