.journey-state-content {
  font-size: 15px;
}

.journey-state.journey-state-alt {
  border-radius: 8px;
  border: solid 0.8px rgba(29, 32, 52, 0.1);
  color: black;
  background-color: #f8f7fa;
}

.journey-state.journey-state-active {
  background-color: #c0dbff;
  border: solid 0.8px rgba(29, 32, 52, 0.1);
}

.journey-state.journey-state-final {
  background-color: #ffcb00;
}

.journey-state.journey-state-current {
  background-color: #0d3ef0;
  color: white;
}

.journey-state {
  border: none;
  border-radius: 8px;
  background-color: white;

  padding: 10px;
  width: 250px;
  height: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  cursor: pointer;
}


.journey-state .react-flow__handle-right {
  visibility: hidden;
}

.journey-state .react-flow__handle-left {
  visibility: hidden;
}
